import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 60px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  @media screen and (max-width: 600px) {
    margin-top: 40px;
  }
`;

const BenefitsTitle = styled.span`
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 32px;
  z-index: 2;

  white-space: nowrap;
  transition: all 0.3s;

  @media screen and (max-width: 600px) {
    font-family: "Gilroy", serif;
    font-style: normal;
    font-size: 26px;
    line-height: 130%;
  }
`;

const BenefitsMenu = styled.nav`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const BenefitsMenuItem = styled.li`
  list-style-type: none;
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #181833;
  transition: all 0.3s;

  @media screen and (max-width: 600px) {
    font-size: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    left: -20px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #181833;

    @media screen and (max-width: 600px) {
      width: 6px;
      height: 6px;
    }
  }
`;

const Benefits = () => {
  return (
    <Wrapper>
      <BenefitsTitle>NFT Benefits:</BenefitsTitle>
      <BenefitsMenu>
        <BenefitsMenuItem>
          VIP access to all next WOW Summits (next one Dubai 8/9 October 2023)
        </BenefitsMenuItem>
        <BenefitsMenuItem>
          Personal manager and business concierge service during all Summits
        </BenefitsMenuItem>
        <BenefitsMenuItem>
          Exclusive discounts for exhibition booths and sponsorship packages
        </BenefitsMenuItem>
        <BenefitsMenuItem>
          10% rewards in WOW tokens equal to WOW Summits ticket sale turnover
          distributed proportionally to 200 NFT owners
        </BenefitsMenuItem>
      </BenefitsMenu>
    </Wrapper>
  );
};

export default Benefits;
